@import './theme.scss';

.CSVImporter_FormatRawPreview {
  &__scroll {
    margin-bottom: 1.2em;
    height: 6em;
    overflow: auto;
    border-radius: $borderRadius;
    background: $invertedBgColor;
    color: $invertedTextColor;
  }

  &__pre {
    margin: 0; // override default
    padding: 0.5em 1em;
    line-height: 1.25;
    font-size: 1.15em;

    & > aside {
      display: inline-block;
      margin-left: 0.2em;
      padding: 0 0.25em;
      border-radius: $borderRadius * 0.5;
      background: $controlBgColor;
      font-size: 0.75em;
      color: $controlBorderColor;
      opacity: 0.75;
    }
  }
}
