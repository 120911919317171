$black: #171717;
$orange: #c15342;
$red: #c31632;
$pink: #B620E0;
$yellow: #c9bdbb;
//$lightblue: #6236FF;
$purple: #B620E0;
//$yellow: #F7B500;
$white: #ffffff;
$blue: #1890ff9c;


@import "scss/style";

.LoginView {
  //background: linear-gradient(-135deg, #c850c0, #4158d0);
  //background: linear-gradient(-135deg, $green, $blue);
  //background: linear-gradient(45deg, rgba(107,52,44,1) 0%, rgba(193,83,66,1) 58%, rgba(254,185,34,1) 100%);
  //background: linear-gradient(45deg, rgba(250,106,84,1) 0%, rgba(193,83,66,1) 58%, rgba(254,185,34,1) 100%);
  background: linear-gradient(0deg, $yellow 0%, $yellow 32%, $red 32%, $red 49%, $yellow 49%);
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2em;

  .card {
    width: 350px;
    display: flex;
    background-color: white;
    flex-direction: column;
    border-radius: 10px;
    padding: 30px 15px;
    align-items: center;
    box-shadow: 0px 0px 15px 1px rgba(0,0,0,0.5);

    > * {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .error {
      color: $red;
      width: 75%;
      text-align: center;
    }

    h1 {
      padding: 0;
    }

    img {
      width: 50px;
      height: 50px;
      border-radius: 10px;
      //margin-bottom: 40px;
    }

    .login-form {
      width: 80%;
      border-bottom: 2px solid $orange;

      input {
        width: 100%;
        border-radius: 5px;
        outline: none;
        border: none;
      }
    }

    .login-btn {
      margin-top: 40px;
      //margin-bottom:40px;
      border-radius: 80px;
      width: 60%;
    }

  }

}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

header .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

header .trigger:hover {
  color: #1890ff;
}

aside .logo {
  height: 32px;
  //background: rgba(255, 255, 255, 0.2);
  margin: 16px;


  > svg {
    fill: $red;
    height: 32px;
  }
}

.ant-layout-sider {
  background-color: $yellow;
}
.ant-menu.ant-menu-dark {
  background-color: $yellow;
}
.ant-menu-dark .ant-menu-item {
  color: $red;
}
.ant-menu-dark .ant-menu-item:hover {
  color: $red;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: $red;
}
.ant-menu-item-selected a, .ant-menu-item-selected a:hover {
  color: $yellow;
}
