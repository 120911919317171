@import './theme.scss';

.CSVImporter_FormatErrorMessage {
  display: flex;
  align-items: center;
  padding: 0.5em 1em;
  border-radius: $borderRadius;
  background: $fillColor;
  color: $errorTextColor;

  & > span {
    flex: 1 1 0;
    margin-right: 1em;
    width: 0; // avoid sizing on inner content
    word-break: break-word;
  }
}
