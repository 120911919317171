@import './theme.scss';

.CSVImporter_TextButton {
  display: block;
  margin: 0; // override default
  border: 1px solid $controlBorderColor;
  padding: 0.4em 1em 0.5em;
  border-radius: $borderRadius;
  background: $fillColor;
  font-size: inherit;
  color: $fgColor;
  cursor: pointer;

  &:hover:not(:disabled) {
    background: darken($fillColor, 10%);
  }

  &:disabled {
    opacity: 0.25;
    cursor: default;
  }
}
